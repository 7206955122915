import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from '@/router';
import Layout from '@/views/home/Layout.vue';

import '@/assets/common/font/font.css'

Vue.use(ElementUI);

new Vue({
    el: '#app',
    router,
    render: h => h(Layout)
});
