import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/views/home/Index.vue'


Vue.use(Router)

export default new Router({
    // mode:' hash ',
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index
        },

    ]
});


//
//
// const router = new Router({
//
//     routes: [
//         {
//             path: '/',
//             name: 'Index',
//             component: Index
//         },
//         // 其他路由配置...
//     ]
// });
//
// // 在路由守卫中监听页面刷新事件
// router.beforeEach((to, from, next) => {
//     if (from.name === null) {
//         next({ name: 'Index' }); // 如果从非内部链接刷新，重定向到首页
//     } else {
//         next(); // 否则，正常导航到目标路由
//     }
// });
//
// export default router;
